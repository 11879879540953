import React from 'react';
import _classes from './FaQ.scss';
import { useNavigate, useParams } from "react-router-dom";

const FaQ = () => {
    const navigate = useNavigate();
    return <div className='faq fade-in-block'>

<h3>What is the difference between Counselling and Psychotherapy?</h3>
<p>
Broadly speaking, counselling provides an environment for you to explore your thoughts and feelings in a safe and supportive way. 
Psychotherapy provides this too, but it also goes a step further and aims to identify and examine the root causes of these feelings. When you understand why you feel like you do, you will be able to make fresh choices moving forwards.
</p>
<h3>How many sessions will I need?</h3>
<p>
The number of sessions required depends on each individual. I provide both open-ended counselling, and also fixed term sessions too. If you wish to concentrate on a specific issue we can agree a set number of sessions (e.g. 6 or 12) to focus on this. 
</p>
<h3>Can I attend more than once a week?</h3>
<p>
Attending sessions on a more frequent basis (two or more times per week) adds greater depth to the therapy and can result in an enhanced level of understanding and wellbeing.
</p>
<h3>How much notice do I have to give to end sessions?</h3>
<p>
If you decide that you no longer want to attend counselling, you can finish our sessions at any time. However if you have attended a number of counselling sessions, I would suggest that we work towards a mutually agreed ending date over a number of weeks.
</p>
<h3>Do I have an accessible office?</h3>
<p>
My office is situated on the ground floor of Worting House and there is accessible parking by the back entrance. There is one small step to navigate on the way to my consulting room.
</p>
<h3>Do I cater for counselling students?</h3>
<p>
Yes, I am able to provide counselling to students who are studying on a psychodynamic or integrative course. 
I may be able to offer a reduction in fee for counselling students. 
</p>
<h3>Do I offer supervision?</h3>
<p>
At this time I have do not have capacity to offer supervision.
</p>
<h3>Can I offer couples counselling?</h3>
<p>
No. I specialise in working with individual adults.
</p>
<h3>Do I offer children or young person counselling?</h3>
<p>
No. I am unable to work with anyone who is under 18 years old.
</p>

        <h3>Where can I find your privacy statement?</h3>
        <p>For details of how I comply with GDPR, click this <span className='link' onClick={() => navigate('/privacy')}>link</span>.</p >
    </div>;
}

export { FaQ };
