import React from 'react';
import _classes from './base.scss';

const Fees = () => {

    return <div className='fees fade-in-block'>
        <h3>Fees</h3>
        <p>
        I charge £60 for a 50 minute session.
        </p>
        <p>
The initial assessment sessions are also charged at £60.
</p>
<p>
If you attend sessions two or more times per week, I can offer a concession on the fee.
</p>
<p>
I may be able to offer a reduced rate to counselling students who are studying on approved counselling or psychotherapy courses in the Basingstoke area. 
</p>
<p>
Fees are payable by BACS and will be invoiced at the end of each month.
</p>
    </div>;
}


export {Fees}